import * as React from 'react';

import Link from '@mui/material/Link';
import Button from './components/modules/components/button';
import withRoot from './components/with_root';
import ResponsiveAppBar2 from './components/navbar_2';

function Error404() {
  return (
    <>
      <ResponsiveAppBar2 />
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component={Link}
        href="/"
        sx={{ minWidth: 200 }}
      >
        Go to Home
      </Button>
    </>
  );
}

export default withRoot(Error404);
