import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@material-ui/core/Link';

import { Link as RouterLink } from 'react-router-dom';

import Container from '@mui/material/Container';
import Typography from './modules/components/typography';
import TextField from './modules/components/textfield';
import withRoot from './with_root';

function Copyright() {
  return (
    <>
      {'© '}
      {new Date().getFullYear()}
      {' Personal Finance Toolkit'}
    </>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark'
  }
};

function AppFooter() {
  return (
    <Typography component="footer" sx={{ display: 'flex', bgcolor: 'secondary.light' }}>
      <Container sx={{ my: 4, display: 'flex' }}>
        <Grid container spacing={12}>
          <Grid item xs={6} sm={4} md={12}>
            <Grid
              container
              // direction="column"
              justifyContent="flex-end"
              spacing={3}
              // sx={{ height: 60 }}
            >
              {/* Copyright item */}
              <Grid item>
                <Copyright />
              </Grid>
              {/* Privacy item */}
              <Grid item>
                <Link component={RouterLink} to="/privacy">
                  Privacy
                </Link>
              </Grid>
              {/* Terms item */}
              <Grid item>
                <Link component={RouterLink} to="/terms">
                  Terms
                </Link>
              </Grid>

              {/* Social items */}
              <Grid item sx={{ display: 'flex' }}>
                <Box component="a" href="https://facebook.com/" sx={iconStyle}>
                  <img
                    src="https://raw.githubusercontent.com/rothbart/onepirate-typescript/main/public/appFooterFacebook.png"
                    alt="Facebook"
                  />
                </Box>
                <Box component="a" href="https://twitter.com/" sx={iconStyle}>
                  <img
                    src="https://raw.githubusercontent.com/rothbart/onepirate-typescript/main/public/appFooterTwitter.png"
                    alt="Twitter"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}

export default withRoot(AppFooter);
