import * as React from 'react';

import AppFooter from './components/footer';
import ResponsiveAppBar2 from './components/navbar_2';

function EmergencyFund() {
  return (
    <>
      <withRoot />
      <ResponsiveAppBar2 />

      <AppFooter />
    </>
  );
}

export default EmergencyFund;
