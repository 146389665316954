import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import Button from './modules/components/button';
import Typography from './modules/components/typography';
import withRoot from './with_root';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5
};

const number = {
  fontSize: 24,
  fontFamily: 'default',
  color: 'secondary.main',
  fontWeight: 'medium'
};

const image = {
  height: 55,
  my: 4
};

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          component="img"
          src="https://raw.githubusercontent.com/rothbart/onepirate-typescript/main/public/appCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14 }}>
          We're on a big mission to make personal finance easy
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src="https://raw.githubusercontent.com/rothbart/onepirate-typescript/main/public/productHowItWorks1.svg"
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  Do you know how much money you should have saved in your emergency fund?
                </Typography>
              </Box>
              <Box sx={item}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  component={Link}
                  to="/emergency-fund"
                  sx={{ mt: 4 }}
                >
                  Calculte now
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src="https://raw.githubusercontent.com/rothbart/onepirate-typescript/main/public/productHowItWorks2.svg"
                  alt="graph"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  How much could you earn over time with the power of compound interest?
                </Typography>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  component={Link}
                  to="/compound-interest"
                  sx={{ mt: 4 }}
                >
                  Find out
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box
                  component="img"
                  src="https://raw.githubusercontent.com/rothbart/onepirate-typescript/main/public/productHowItWorks3.svg"
                  alt="clock"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  If you don't know where to start, head on over to our Learning Hub!
                </Typography>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  component={Link}
                  to="/learning-hub"
                  sx={{ mt: 4 }}
                >
                  Start learning
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
}

export default withRoot(ProductHowItWorks);
