import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link as RouterLink } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import AppFooter from '../components/footer';

import ResponsiveAppBar2 from '../components/navbar_2';
import withRoot from '../components/with_root';

const cards = [
  {
    key: 1,
    heading: 'The importance of having an emergency fund',
    url: '/learning-hub/emergency-fund',
    content:
      'It’s one of the most important things you can do to protect yourself from financial hardship.',
    image_url: 'https://source.unsplash.com/NeTPASr-bmQ?auto=format&fit=crop&w=400'
  },
  {
    key: 2,
    url: '/',
    heading: 'This article is coming soon...',
    content: 'Watch this space! New finance content will be added shortly.',
    image_url:
      'https://images.pexels.com/photos/3843284/pexels-photo-3843284.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    key: 3,
    url: '/',
    heading: 'This article is coming soon...',
    content: 'Watch this space! New finance content will be added shortly.',
    image_url:
      'https://images.pexels.com/photos/3843284/pexels-photo-3843284.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  }
];

function LearningHubIndexHero() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <main>
      {/* Hero unit */}
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 8,
          pb: 6
        }}
      >
        <Container maxWidth="sm">
          <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
            learning hub
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            The Learning Hub is a place where you can learn about personal finances, learn to be
            better with money, from basic to more advanced. You can also find out how to manage your
            current finances, or how to save for the future.
          </Typography>
        </Container>
      </Box>
      {/* End hero unit */}
    </main>
  );
}

function LearningHubItems() {
  return (
    <main>
      <Container sx={{ py: 4 }} maxWidth="md">
        {/* End hero unit */}

        <Grid container spacing={4}>
          {cards.map((card) => (
            <Grid item key={card.key} xs={12} sm={6} md={4}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardMedia
                  component="img"
                  sx={
                    {
                      // 16:9
                      // pt: '56.25%'
                    }
                  }
                  image={card.image_url}
                  alt="random"
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {card.heading}
                  </Typography>
                  <Typography>{card.content}</Typography>
                </CardContent>

                <CardActions style={{ justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    component={RouterLink}
                    to={card.url}
                    style={{ width: '90%', margin: '5px', align: 'center' }}
                  >
                    View
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </main>
  );
}

function LearningHubIndex() {
  return (
    <>
      <ResponsiveAppBar2 />
      <LearningHubIndexHero />
      <LearningHubItems />

      <AppFooter />
    </>
  );
}

export default withRoot(LearningHubIndex);
