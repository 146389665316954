import * as React from 'react';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useBetween } from 'use-between';
import { Box, TextField } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ResponsiveLine } from '@nivo/line';
import { InputAdornment } from '@mui/material';
import AppFooter from './components/footer';
import Recommendations from './components/recommendations';
import withRoot from './components/with_root';
import ResponsiveAppBar2 from './components/navbar_2';
import Button from './components/modules/components/button';

// Make a custom hook with your future shared state
const useFormState = () => {
  const [responseData, setResponseData] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [res, setRes] = React.useState([]);
  const [showGraph, setShowGraph] = React.useState(false);
  return {
    responseData,
    setResponseData,
    res,
    setRes,
    tableData,
    setTableData,
    showGraph,
    setShowGraph
  };
};

// Make a custom hook for sharing your form state between any components
const useSharedFormState = () => useBetween(useFormState);

function CompoundInterestForm({ handleCompoundInterestTotal }) {
  const baseUrl = 'https://monkfish-app-lymbn.ondigitalocean.app';
  const [amount, setAmount] = React.useState('');
  const [duration, setDuration] = React.useState('');
  const [rate, setRate] = React.useState('');
  const [deposit, setDeposit] = React.useState('');
  const [error, setError] = React.useState(null);
  const { setResponseData } = useSharedFormState();

  const onSubmitEvent = (e) => {
    e.preventDefault();

    const formattedRate = rate / 100;
    const requestBody = {
      amount,
      duration,
      rate: formattedRate,
      deposit
    };

    fetch(`${baseUrl}/compound-interest/`, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(requestBody)
    })
      .then((response) => response.json())
      .then((data) => {
        setResponseData(data);
        handleCompoundInterestTotal(data.total);
        setError(null);
      })
      .catch((error) => {
        setError('Something went wrong, please try again later.');
        console.log(JSON.stringify(error.response));
      });
  };

  return (
    <Box component="section" sx={{ display: 'flex', overflow: 'hidden' }}>
      <Container
        sx={{
          mt: 1,
          mb: 1,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          component="img"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7
          }}
        />

        <Box
          component="form"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            py: 4,
            px: 3
          }}
          noValidate
          autoComplete="off"
          align="center"
          width="100%"
        >
          <Box sx={{ maxWidth: 4000, width: '100%' }}>
            {/* <Typography variant="h6">What are your total monthly expense?</Typography> */}
            <TextField
              style={{ width: '80%', margin: '5px' }}
              type="number"
              label="amount"
              variant="outlined"
              onChange={(event) => setAmount(event.target.value)}
              value={amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>
              }}
            />
            <TextField
              style={{ width: '80%', margin: '5px' }}
              type="number"
              label="duration"
              variant="outlined"
              onChange={(event) => setDuration(event.target.value)}
              value={duration}
              InputProps={{
                endAdornment: <InputAdornment position="end">Years</InputAdornment>
              }}
            />
            <TextField
              style={{ width: '80%', margin: '5px' }}
              type="number"
              label="rate"
              variant="outlined"
              onChange={(event) => setRate(event.target.value)}
              value={rate}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
            <TextField
              style={{ width: '80%', margin: '5px' }}
              type="number"
              label="deposit"
              variant="outlined"
              onChange={(event) => setDeposit(event.target.value)}
              value={deposit}
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>
              }}
            />
            <br />
            <Button
              variant="contained"
              color="secondary"
              onClick={onSubmitEvent}
              type="submit"
              style={{ width: '80%', margin: '5px' }}
            >
              Calculate
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

function CompoundInterestInput() {
  const { setShowGraph } = useSharedFormState();

  const handleCompoundInterestTotal = () => {
    // 👇️ take parameter passed from Child component
    setShowGraph(true);
  };

  return (
    <Grid item xs={12} sm={6} md={6} sx={{ height: '100%' }}>
      <Paper
        xs="true"
        variant="outlined"
        sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Compound Interest Calculator
        </Typography>
        <Divider />
        <CompoundInterestForm handleCompoundInterestTotal={handleCompoundInterestTotal} />
      </Paper>
    </Grid>
  );
}

// eslint-disable-next-line consistent-return
function generateData(arrayData) {
  const { responseData } = useSharedFormState();
  const interest = [];
  const totals = [];
  const deposits = [];
  const depositsCumulative = [];
  const interestCumulative = [];
  if (responseData.interest === undefined) {
    return [];
  }
  if (Array.isArray(arrayData)) {
    arrayData.forEach(function (item, index) {
      interest.push({
        x: index + 1,
        y: Number(item)
      });
      totals.push({
        x: index + 1,
        y: Number(responseData.totals[index])
      });
      deposits.push({
        x: index + 1,
        y: Number(responseData.deposits[index])
      });
      depositsCumulative.push({
        x: index + 1,
        y: Number(responseData.deposits_cumulative[index])
      });
      interestCumulative.push({
        x: index + 1,
        y: Number(responseData.interest_cumulative[index])
      });
    });
    return [
      {
        id: 'Interest',
        color: 'hsl(228, 70%, 50%)',
        data: interest
      },
      {
        id: 'Interest Cumulative',
        color: 'hsl(228, 70%, 50%)',
        data: interestCumulative
      },
      {
        id: 'Deposits',
        color: 'hsl(228,48%,43%)',
        data: deposits
      },
      {
        id: 'Deposits Cumulative',
        color: 'hsl(227,33%,28%)',
        data: depositsCumulative
      },
      {
        id: 'Totals',
        color: 'hsl(228,30%,39%)',
        data: totals
      }
    ];
  }
}

// TODO - Move to utils?
function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

function generateTableData(arrayData) {
  const { responseData } = useSharedFormState();

  const tableData = [];
  if (responseData.interest === undefined) {
    return [];
  }
  if (Array.isArray(arrayData)) {
    arrayData.forEach(function (item, index) {
      tableData.push({
        interest: Number(item),
        year: String(index + 1),
        totals: Number(responseData.totals[index]),
        deposits: Number(responseData.deposits[index]),
        deposits_cumulative: Number(responseData.deposits_cumulative[index]),
        interest_cumulative: Number(responseData.interest_cumulative[index])
      });
    });
    return tableData;
  }
  return [];
}

function CompoundInterestTable() {
  const { responseData } = useSharedFormState();

  return (
    <Grid item xs={12} sm={6} md={6} sx={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <Paper
          variant="outlined"
          xs="true"
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxHeight: '100%',
            overflowX: 'auto'
          }}
        >
          <TableContainer
            sx={{
              height: '58vh',
              minHeight: '58vh',
              overflowX: 'auto'
            }}
          >
            <Table
              size="small"
              aria-label="simple table"
              sx={{
                height: 'max-content'
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Interest</TableCell>
                  <TableCell>Interest Cumulative</TableCell>
                  <TableCell>Deposits</TableCell>
                  <TableCell>Deposits Cumulative</TableCell>
                  <TableCell>Totals</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {generateTableData(responseData.interest).map((row) => (
                  <TableRow key={row.interest}>
                    <TableCell>{row.year}</TableCell>
                    <TableCell>£{numberWithCommas(row.interest)}</TableCell>
                    <TableCell>£{numberWithCommas(row.interest_cumulative)}</TableCell>
                    <TableCell>£{numberWithCommas(row.deposits)}</TableCell>
                    <TableCell>£{numberWithCommas(row.deposits_cumulative)}</TableCell>
                    <TableCell>£{numberWithCommas(row.totals)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </Grid>
  );
}

function CompoundInterestGraph() {
  const { responseData } = useSharedFormState();
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Paper
        xs="true"
        variant="outlined"
        sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <div style={{ height: 400 }}>
          <ResponsiveLine
            data={generateData(responseData.interest)}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              reverse: false
            }}
            yFormat=" >-$.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Number of years',
              legendOffset: 36,
              legendPosition: 'middle'
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Amount (£)',
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
        </div>
      </Paper>
    </Grid>
  );
}

function LoadingComponent() {
  const { count } = useSharedFormState();

  return (
    <Grid item xs={12} sm={6} md={6}>
      <Paper
        variant="outlined"
        xs="true"
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          What is compound interest?
        </Typography>
        <Divider />
        <Typography sx={{ py: 3 }} className="MuiTypography--subheading">
          Compound interest is the process of earning interest on the initial principal and also on
          the accumulated interest that has been earned.
          <br />
          The main idea behind compound interest is that it allows for the exponential growth of the
          original sum. This means that a small amount of money can grow into a large amount if
          given enough time to compound.
        </Typography>

        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          why is compound interest so powerful?
        </Typography>
        <Divider />
        <Typography sx={{ py: 3 }} className="MuiTypography--subheading">
          Compound interest is the most powerful force in the universe because it operates on
          exponential growth rather than a linear increase. Compound interest occurs when interest
          on an investment is added to the principal sum so that both generate more interest.
        </Typography>
      </Paper>
    </Grid>
  );
}

function CompoundInterest() {
  const { showGraph } = useSharedFormState();

  const renderResult = () => {
    if (showGraph) {
      return (
        <>
          <CompoundInterestTable />
          <CompoundInterestGraph />
        </>
      );
    }
    return <LoadingComponent />;
  };

  return (
    <>
      <ResponsiveAppBar2 />
      <div>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3} alignItems="stretch">
            <CompoundInterestInput />

            {renderResult()}
            <Recommendations />
          </Grid>
        </Container>
        <AppFooter />
      </div>
    </>
  );
}

export default withRoot(CompoundInterest);
