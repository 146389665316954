import * as React from 'react';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppFooter from '../components/footer';
import withRoot from '../components/with_root';
import ResponsiveAppBar2 from '../components/navbar_2';
import Post1 from './post_1';

function TermsAndConditions() {
  const backgroundImage = 'https://source.unsplash.com/6jegMsYM6CY?auto=format&fit=crop&w=140';

  return (
    <div>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${backgroundImage})`
        }}
      >
        {/* Increase the priority of the hero background image */}
        <img style={{ display: 'none' }} src={backgroundImage} alt="imageText" />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)'
          }}
        />
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6 },
                pr: { md: 0 }
              }}
            >
              <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                The Importance of Having an Emergency Fund
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                A Guide To Building Yours
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <div>
        <Post1 />
      </div>
    </div>
  );
}
function LearningSingle() {
  return (
    <>
      <ResponsiveAppBar2 />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={12} md={12} sx={{ height: '100%' }}>
            <Paper
              xs
              variant="outlined"
              sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <TermsAndConditions />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <AppFooter />
    </>
  );
}

export default withRoot(LearningSingle);
