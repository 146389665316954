import * as React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { useLayoutEffect } from 'react';
import ProductHero from './components/hero';
import AppFooter from './components/footer';
import ProductCategories from './components/product_categories';
import ProductHowItWorks from './components/product_how_it_works';
import ProductCTA from './components/product_cta';

import withRoot from './components/with_root';
import theme from './components/theme';

import ResponsiveAppBar2 from './components/navbar_2';

function App() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar2 />
      <ProductHero />
      <ProductHowItWorks />
      <ProductCategories />
      <AppFooter />
    </ThemeProvider>
  );
}

export default withRoot(App);
