import * as React from 'react';
import { Link } from 'react-router-dom';
import Button from './modules/components/button';
import Typography from './modules/components/typography';
import ProductHeroLayout from './modules/product_hero_layout';
import withRoot from './with_root';

const backgroundImage = 'https://source.unsplash.com/JhevWHCbVyw?auto=format&fit=crop&w=1400';

const mainHeader = 'Want to improve your financial literacy?';
const secondaryHeader =
  'Our tools can help you avoid financial mistakes and improve your money management skills.';
const thirdHeader = '';

const buttonCTA = 'open your toolkit';

function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center'
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        {mainHeader}
      </Typography>
      <Typography color="inherit" align="center" variant="h5" sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}>
        {secondaryHeader}
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component={Link}
        to="/learning-hub"
        sx={{ minWidth: 200 }}
      >
        {buttonCTA}
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        {thirdHeader}
      </Typography>
    </ProductHeroLayout>
  );
}

export default withRoot(ProductHero);
