import * as React from 'react';
import ReactDOM from 'react-dom/client';

import { Routes, Route, HashRouter, Navigate, BrowserRouter } from 'react-router-dom';
import { hydrate, render } from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import Cookies from './Cookies';
import Terms from './Terms';
import Privacy from './Privacy';
import EmergencyFund from './EmergencyFund';
import CompoundInterest from './CompoundInterest';
import RecessionSurvivability from './RecessionSurvivability';
import Error404 from './404';
import SignUp from './SignUp';
import LearningHubIndex from './learning/LearningIndex';
import LearningSingle from './learning/LearningSingle';
import SignIn from './signIn';

const tagManagerArgs = {
  gtmId: 'GTM-PVTMVKF'
};

TagManager.initialize(tagManagerArgs);

function BaseApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/cookies" element={<Cookies />} />
        <Route exact path="/emergency-fund" element={<EmergencyFund />} />
        <Route exact path="/compound-interest" element={<CompoundInterest />} />
        <Route exact path="/recession-survivability" element={<RecessionSurvivability />} />
        <Route exact path="/sign-up" element={<SignUp />} />
        <Route exact path="/sign-in" element={<SignIn />} />
        <Route exact path="/learning-hub" element={<LearningHubIndex />} />
        <Route exact path="/learning-hub/emergency-fund" element={<LearningSingle />} />
        <Route path="/404" element={<Error404 />} />

        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
}

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<BaseApp />, rootElement);
} else {
  render(<BaseApp />, rootElement);
}
