import * as React from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useEffect } from 'react';
import withRoot from './with_root';
import Button from './modules/components/button';

function Recommendations() {
  const baseUrl = 'https://monkfish-app-lymbn.ondigitalocean.app';
  const [data, setData] = React.useState([]);

  useEffect(() => {
    fetch(`${baseUrl}/savings-accounts/`, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    })
      .then((response) => response.json())
      .then((resp) => {
        setData(resp.savings_accounts);
      })
      .catch((error) => {});
  }, []);
  return (
    data.length > 0 && (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3} alignItems="stretch">
          {/* saving accounts */}
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography variant="h5">Savings account offers</Typography>
              <br />
              <Grid container spacing={3}>
                {data.map((card) => (
                  <Grid item key={card.title} xs={12} sm={6} md={3}>
                    <Card
                      variant="outlined"
                      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                    >
                      <CardMedia
                        component="img"
                        sx={{
                          height: '20vh'
                        }}
                        image={card.image_url}
                        alt="random"
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="h2">
                          {card.title}
                        </Typography>
                        <Typography>{card.summary}</Typography>
                      </CardContent>
                      <CardActions style={{ justifyContent: 'center' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          href={card.url}
                          style={{ width: '90%', margin: '5px', align: 'center' }}
                        >
                          Find out more
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    )
  );
}

export default withRoot(Recommendations);
