import * as React from 'react';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { useBetween } from 'use-between';
import { Box, TextField } from '@material-ui/core';
import Slider from '@mui/material/Slider';
import { InputAdornment } from '@mui/material';
import { useLayoutEffect } from 'react';
import AppFooter from './components/footer';
import Recommendations from './components/recommendations';
import withRoot from './components/with_root';
import ResponsiveAppBar2 from './components/navbar_2';
import Button from './components/modules/components/button';

// Make a custom hook with your future shared state
const useFormState = () => {
  const [count, setCount] = React.useState(0);
  return {
    count,
    setCount
  };
};

// Make a custom hook for sharing your form state between any components
const useSharedFormState = () => useBetween(useFormState);

function EmergencyFundForm({ handleEmergencyFundTotal }) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const baseUrl = 'https://monkfish-app-lymbn.ondigitalocean.app';
  // Slider config
  const [sliderValue, setSliderValue] = React.useState(3);
  const [amount, setAmount] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const changeSliderValue = (event, sliderValue) => {
    setSliderValue(sliderValue);
  };
  const getText = (sliderValue) => `${sliderValue}`;

  const onSubmitEvent = (e) => {
    e.preventDefault();
    const requestBody = {
      amount,
      duration: sliderValue
    };

    fetch(`${baseUrl}/emergency-fund/`, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(requestBody)
    })
      .then((response) => response.json())
      .then((data) => {
        handleEmergencyFundTotal(data.total);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setLoading(false);
        setError('Something went wrong, please try again later.');
        alert(JSON.stringify(error.response));
      });
  };

  return (
    <Box component="section" sx={{ display: 'flex', overflow: 'hidden' }}>
      <Container
        sx={{
          mt: 1,
          mb: 1,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          component="img"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7
          }}
        />

        <Box
          component="form"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            py: 4,
            px: 3
          }}
          noValidate
          autoComplete="off"
          align="center"
          width="100%"
        >
          <Box component="form" sx={{ maxWidth: 4000, width: '100%' }}>
            <br />
            <Typography variant="h6">What are your total monthly expenses?</Typography>
            <br />
            <TextField
              style={{ width: '80%', margin: '5px' }}
              type="number"
              label="Monthly expenses"
              variant="outlined"
              onChange={(event) => setAmount(event.target.value)}
              value={amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>
              }}
            />
            <br />
            <br />

            <Typography variant="h6">
              How many months would you like your emergency fund to last?
            </Typography>
            <Slider
              aria-label="Temperature"
              defaultValue={6}
              getAriaValueText={getText}
              valueLabelDisplay="auto"
              value={sliderValue}
              marks
              onChange={changeSliderValue}
              step={1}
              min={1}
              max={12}
              sx={{ width: '75%' }}
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="secondary"
              onClick={onSubmitEvent}
              type="submit"
              style={{ width: '80%', margin: '5px' }}
            >
              Calculate
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

function EmergencyFundInput() {
  const { setCount } = useSharedFormState();

  const handleEmergencyFundTotal = (num) => {
    // 👇️ take parameter passed from Child component
    setCount(num);
  };

  return (
    <Grid item xs={12} sm={6} md={6} sx={{ height: '100%' }}>
      <Paper
        xs
        variant="outlined"
        sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Emergency Fund Calculator
        </Typography>
        <Divider />

        <EmergencyFundForm handleEmergencyFundTotal={handleEmergencyFundTotal} />
      </Paper>
    </Grid>
  );
}

function EmergencyFundResult() {
  const { count } = useSharedFormState();

  return (
    <Grid item xs={12} sm={6} md={6}>
      <Paper
        variant="outlined"
        xs
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Typography component="h1" variant="h6" color="primary" gutterBottom>
          You would need an emergency fund of{' '}
        </Typography>
        <Divider />
        <Typography sx={{ py: 4 }} component="p" variant="h4">
          £{count}
        </Typography>
        <Divider />
        <br />
        <Typography component="h1" variant="h6" color="primary" gutterBottom>
          Why is Having an Emergency Fund so Important?
        </Typography>
        <Typography className="MuiTypography--subheading">
          Having an emergency fund may not sound exciting, but it’s one of the most important things
          you can do to protect yourself from financial hardship.
          <br />
          The amount you should be saving in your emergency fund really depends on what type of
          emergencies you’re expecting, your current financial situation, and your goals for the
          future. There isn’t an exact amount that is right for everyone, but a good rule of thumb
          is to have at least three to six months worth of expenses saved in your fund.
        </Typography>
        <br />
        <div>
          <Link color="primary" href="/learning-hub/emergency-fund">
            Learn more
          </Link>
        </div>

        <br />
      </Paper>
    </Grid>
  );
}

function EmergencyFund() {
  return (
    <>
      <ResponsiveAppBar2 />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3} alignItems="stretch">
          <EmergencyFundInput />
          <EmergencyFundResult />
          <Recommendations />
        </Grid>
      </Container>
      <AppFooter />
    </>
  );
}

export default withRoot(EmergencyFund);
